<template>
  <div>
    <div class="text-center">
      <h1>
        Commodities
      </h1>
      <p>
        This is a force directed graph where a link symbolize a border between
        two countries.
      </p>
    </div>
    <div>
      <pre>

      <!-- {{ countries }} -->
      <!-- {{commodities}} -->
      {{commoditiesCountries}}
      </pre>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
// import Fuse from "fuse.js";
// import * as d3 from "d3";

export default {
  name: "Commodities",
  data() {
    return {
      factbook: null,
      countries: null,
      commodities: null,
      commoditiesCountries: null,
    };
  },
  watch: {},
  mounted() {
    axios
      .get(
        "https://raw.githubusercontent.com/iancoleman/cia_world_factbook_api/master/data/factbook.json"
      )
      .then((response) => {
        console.log("response", response, response.data);
        var data = response.data;
        // this.factbook = data;
        this.countries = _.values(data.countries).map((c) => {
          return {
            name: c.data.name,
            commodities: {
              exports: c.data.economy.exports?.commodities?.by_commodity,
              imports: c.data.economy.imports?.commodities?.by_commodity,
            },
          };
        });
        // alert("loaded");
      })
      .then(() => {
        this.extractCommodities();
      })
      .then(() => {
        this.setCommoditiesCountries();
      });
  },
  methods: {
    sanitize(str) {
      return str
        .split("(")[0]
        .replace(/[0-9]/g, "")
        .split(".")
        .join("")
        .split(")")
        .join("")
        .trim();
    },
    extractCommodities() {
      var commodities = new Set();
      console.log(this.countries.length);
      this.countries
        .filter((c) => c.name != "World")
        .forEach((country) => {
          // console.log(country);
          var coms =
            [
              ...(country?.commodities?.exports || []),
              ...(country?.commodities?.imports || []),
            ] || [];
          coms.forEach((c) => {
            // console.log(c);
            var s = this.sanitize(c);
            if (s && s != "NA" && !s.includes("%")) {
              commodities.add(s);
            }
          });
        });
      this.commodities = [...commodities].sort();
      // console.log(commodities);
    },
    setCommoditiesCountries() {
      var commoditiesCountries = [];
      this.commodities.forEach((commodity) => {
        commoditiesCountries.push({
          name: commodity,
          importedBy: this.countries
            .filter((c) =>
              c?.commodities?.imports
                ?.map((c) => this.sanitize(c))
                .includes(commodity)
            )
            .map((c) => c.name),
          exportedBy: this.countries
            .filter((c) =>
              c?.commodities?.exports
                ?.map((c) => this.sanitize(c))
                .includes(commodity)
            )
            .map((c) => c.name),
        });
      });
      this.commoditiesCountries = commoditiesCountries.sort(
        (a, b) => b.importedBy.length - a.importedBy.length
      );
      // console.log("commoditiesCountries", commoditiesCountries);
    },
  },
  computed: {},
};
</script>

<style scoped>
.svgContainer {
  border: 2vw solid black;
}
</style>
